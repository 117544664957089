'use client';

import { useInView } from '@vcc-www/hooks';
import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import type React from 'react';
import { useState } from 'react';
import styles from '../SubNavigation.module.css';
import { debounce } from '../utils/debounce';
import { useResizeObserver } from '../utils/useResizeObserver';
import { useSubNavigationScroll } from '../utils/useSubNavigationScroll';
import { Link } from './Link.view';

export type LinkProps = {
  text: string;
  href: string;
  eventLabel: string;
  sources?: {
    text?: string;
    href?: string;
  };
};

export type LinksProps = {
  links: LinkProps[];
  /* Determines the background of the currently active link, only has an effect when colorMode of container is "light" */
  theme?: 'light' | 'dark';
};

const Links: React.FC<LinksProps> = ({ links, theme = 'light' }) => {
  const [navRef, isInView] = useInView<HTMLDivElement>();
  const [isScrollable, setIsScrollable] = useState(false);

  const navHasScrollbar = () => {
    if (navRef.current) {
      return navRef.current.scrollWidth > navRef.current.clientWidth;
    }
    return false;
  };

  useResizeObserver({
    ref: navRef,
    callback: debounce(() => {
      if (links.length > 0 && navHasScrollbar()) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }, 10),
  });

  const { scrollPosition } = useSubNavigationScroll(navRef);

  if (links.length === 0) return null;

  return (
    <div slot="main" className={styles.links}>
      <div
        className={cssJoin('flex items-center', styles.scrollBack)}
        style={{
          opacity: scrollPosition !== 'start' && isScrollable ? 1 : 0,
        }}
      >
        <div className={styles.scrollBackground}>
          <div className="flex justify-center items-center w-40 h-40">
            <Icon icon="chevron-back" size={16} />
          </div>
        </div>
      </div>
      <div className="relative reel scrollbar-none h-full" ref={navRef}>
        <ul
          className={cssJoin('flex gap-8 items-center mx-auto md:px-0')}
          data-autoid="sub-navigation:links"
          data-testid="sub-navigation:links"
        >
          {links.map((link) => (
            <li key={link.text}>
              <Link {...link} theme={theme} isInView={isInView} />
            </li>
          ))}
        </ul>
      </div>
      <div
        className={cssJoin(
          'flex items-center justify-end',
          styles.scrollForward,
        )}
        style={{
          opacity: scrollPosition !== 'end' && isScrollable ? 1 : 0,
        }}
      >
        <div className={styles.scrollBackground}>
          <div className="flex justify-center items-center w-40 h-40">
            <Icon icon="chevron-forward" size={16} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Links;
