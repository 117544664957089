import { useEffect, useState } from 'react';

function useScrollbarGutterSize() {
  const [gutterSize, setGutterSize] = useState(0);

  useEffect(() => {
    function measureScrollbarGutter() {
      const div = document.createElement('div');
      div.style.width = '100px';
      div.style.height = '100px';
      div.style.overflowY = 'scroll';
      div.style.position = 'absolute';
      div.style.top = '-9999px';
      document.body.appendChild(div);

      const size = div.offsetWidth - div.clientWidth;
      document.body.removeChild(div);
      return size;
    }

    function updateGutterSize() {
      setGutterSize(measureScrollbarGutter());
    }

    if (typeof ResizeObserver === 'function') {
      const observer = new ResizeObserver(updateGutterSize);
      observer.observe(document.documentElement); // Observe changes in the root element

      updateGutterSize(); // Initial measurement

      return () => observer.disconnect();
    }
  }, []);

  return gutterSize;
}

export default useScrollbarGutterSize;
