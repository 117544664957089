import { embeddableFormsApiBaseUrl } from '@vcc-package/embeddable-forms';

import { appEnvByHostname, hostnameByAppEnv } from './appEnvByHostname';
import type {
  usePublicConfigProps,
  usePublicConfigReturnType,
} from './clientConfig';
import { getPublicEnv } from './public-env';

export type PublicRuntimeConfig = {
  apiTimeout?: number;
  setBy: 'server' | 'client';
  rootPath: string;
  assetPath: string;
  apiHost: string;
  deployEnv: 'dev' | 'test' | 'qa' | 'prod';
  appEnv: 'dev' | 'test' | 'qa' | 'prod';
  commitId: string;
  hideMissingTranslationMessage: string;
  authHeaderName: string;
  authHeaderValue: string;
  useCors: boolean;
  port: string;
  version: string;
  trailingSlash: boolean;
};

export type OptionalPublicRuntimeConfig = PublicRuntimeConfig | null;

export const getLocationBasedEnvProps = () => {
  if (typeof window === 'undefined') {
    return {};
  }

  const location = window.location;
  const hostname = location.hostname;

  const appEnv = appEnvByHostname(hostname) ?? 'prod';
  const isLocal = appEnv === 'dev';
  const useCors = isLocal || hostname !== hostnameByAppEnv[appEnv];

  const apiHost = useCors ? embeddableFormsApiBaseUrl : location.origin;
  const assetPath = isLocal
    ? '../'
    : `${useCors ? embeddableFormsApiBaseUrl : ''}/static/leads-forms/`;

  return {
    setBy: 'client',
    appEnv,
    apiHost,
    deployEnv: appEnv,
    assetPath,
    useCors,
    authHeaderName: useCors ? 'QAWWW' : '',
    authHeaderValue: useCors ? '32204422-3a1d-49f7-9e93-e4de131c7885' : '',
    hideMissingTranslationMessage: appEnv === 'prod' ? 'true' : 'false',
    trailingSlash: false,
  };
};

export const ssrPublicConfig = <T extends usePublicConfigProps>() => {
  //NOTE: This will be migrated to the new config system as a later date
  try {
    const ssrPublicRuntimeConfig = getPublicEnv() ?? null;
    //NOTE: Server side rendering does not need CORS
    ssrPublicRuntimeConfig.useCors = false;

    ssrPublicRuntimeConfig.setBy = 'server' as PublicRuntimeConfig['setBy'];

    return ssrPublicRuntimeConfig as usePublicConfigReturnType<T>;
  } catch (e) {
    console.info('Error getting publicRuntimeConfig', e);
  }

  return null as usePublicConfigReturnType<T>;
};
