import type {
  CarouselGraphCarData,
  CarouselGraphResponseType,
} from '../types/graphTypes';

const graphqlServerUrl = 'https://www.volvocars.com/api/graphql';

type RequestVariables = {
  market: string;
  locale: string;
  carModel: string;
  modelYear: string;
  onError?: (e: unknown) => void;
  cacheTags?: string[];
};

export const fetchCarouselData = async ({
  onError,
  cacheTags,
  ...variables
}: RequestVariables) => {
  try {
    const response = await fetch(graphqlServerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apollographql-client-name': 'carousel',
      },
      body: JSON.stringify({ query: carouselQuery, variables }),
      next: {
        revalidate: 60 * 60,
        tags: ['car-carousel', ...(cacheTags ?? [])],
      },
    });

    if (!response.ok) {
      throw new Error(
        `Network error: ${response.status} - ${response.statusText}`,
      );
    }

    const jsonResponse: CarouselGraphResponseType = await response.json();

    const modelYears = jsonResponse?.data?.carsByMarket?.sortedOnPrice?.groupBy;
    const carData = modelYears
      ? getLatestCarFromGroupBy(modelYears)
      : undefined;

    return {
      carData,
      warnings: jsonResponse?.extensions?.warnings || [],
    };
  } catch (error) {
    onError?.(error);
    return {
      undefined,
      warnings: [],
    };
  }
};

const getLatestCarFromGroupBy = (
  groupBy: NonNullable<
    CarouselGraphResponseType['data']
  >['carsByMarket']['sortedOnPrice']['groupBy'],
): CarouselGraphCarData | undefined => {
  let carData: CarouselGraphCarData | undefined;
  // reverse array loop to find the first non-empty group
  for (let i = groupBy.length - 1; i >= 0; i--) {
    const group = groupBy[i].groupBy;
    if (group.length > 0) {
      // Retrieve the first car data in this non-empty group
      carData = group[0].first;
      break;
    }
  }
  return carData;
};

const ModelFragment = `
fragment Model on Car{
  model(locale: $locale) {
    key
    displayName {
      value
    }
  }
}`;

const EngineFragment = `
fragment Engine on Car {
  engine {
    content(locale: $locale) {
      engineType {
        formatted
        value
      }
    }
  }
}`;

const CarTypeFragment = `
fragment CarType on Car {
  carType {
    content(locale: $locale) {
      carTypeCategory {
        formatted
        value
      }
    }
  }
}`;

const VisualizationFragment = `
fragment Visualization on Car {
  visualizations(marketOrLocale: $locale) {
    galleries {
      custom(groupKeys: "standardized-side-view") {
        images {
          small {
            transparentUrl
          }
        }
      }
    }
  }
}`;

const CarPriceFragment = `
fragment CarPrice on Car {
  cashPrice: priceSummary(priceConfigInput: { locale: $locale, name: "cash" })
  {
      price {
      display
    }
  }
}`;

const ConfigurationFragment = `
fragment Configuration on Car {
  configuration {
    colors: components(type: COLOR) {
      configurations {
        car {
          visualizations(marketOrLocale: $locale) {
            galleries {
              custom(groupKeys: "standardized-side-view") {
                images {
                  small {
                    transparentUrl
                  }
                }
              }
            }
          }
          color {
            code
            content(locale: $locale) {
              hex {
                value
              }
            }
          }
        }
      }
    }
  }
}`;

const ColorFragment = `
fragment Color on Car {
  color {
    code
    content(locale: $locale) {
      hex {
        value
      }
    }
  }
}`;

const carouselQuery = `
query Query($market: String!, $carModel: String, $locale: String!, $modelYear: String) {
  carsByMarket(market: $market) {
    sortedOnPrice(
      sortMissingPriceAs: PlaceLast
      priceConfigInput: { locale: $locale, name: "cash" }
    ) {
      groupBy(groupType: COMMERCIAL_MODEL_YEAR, filter: $modelYear) {
        groupBy(groupType: MODEL, filter: $carModel) {
          first {
            ...Model
            ...Engine
            ...CarType
            ...Visualization
            ...CarPrice
            ...Configuration
            ...Color
          }
        }
      }
    }
  }
}
${ModelFragment}
${EngineFragment}
${CarTypeFragment}
${VisualizationFragment}
${CarPriceFragment}
${ConfigurationFragment}
${ColorFragment}
`;
