import { CtaTrackingWrapper } from '@vcc-package/car-selector/tracking/cta-tracking-wrapper';
import { cssJoin } from '@volvo-cars/css/utils';
import type React from 'react';
import type { CarPriceViewProps } from '../../car-price.types';
import { CarPriceViewSkeleton } from '../../car-price.view.skeleton';
import styles from './car-price.view.module.css';

const openLocationModal = () => {
  if (window.siteNavigationController) {
    window.siteNavigationController.toggleLocationDialog();
  }
};

export const CarPriceView = (props: CarPriceViewProps) => {
  const {
    carPrice,
    carPriceSources,
    variant,
    layout,
    loading,
    isValidCarPrice = true,
    noPriceFallback,
    'data-nosnippet': dataNoSnippet,
    selectedLocation,
    enableSelectedLocation,
  } = props;

  if (!isValidCarPrice) {
    return typeof noPriceFallback === 'function'
      ? noPriceFallback()
      : (noPriceFallback ?? null);
  }

  if (loading) {
    return <CarPriceViewSkeleton variant={variant} layout={layout} />;
  }

  return (
    <span
      {...(dataNoSnippet ? { 'data-nosnippet': true } : undefined)}
      className={cssJoin(
        layout?.includes('COMPACT') && 'micro flex-grow',
        layout?.includes('BOLD') && 'font-medium',
        !enableSelectedLocation && 'font-14', //We use enableSelectedLocation to tell if it is submenu(false) or introPriceOverLay, and make the car price component smaller for pdp submenu
      )}
      data-car-price="us"
      data-sources={carPriceSources}
    >
      {carPrice}
      {enableSelectedLocation &&
        (!selectedLocation ? (
          <div className="pb-16">
            <CtaTrackingWrapper
              eventCategory="location-selector"
              eventAction="link|click"
              eventLabel="Set Location"
            >
              <button
                className="button-text"
                type="button"
                data-color="accent"
                onClick={openLocationModal}
              >
                Set Location
              </button>
            </CtaTrackingWrapper>
          </div>
        ) : (
          <div>
            <CtaTrackingWrapper
              eventCategory="location-selector"
              eventAction="link|click"
              eventLabel="Change Location"
            >
              <div>
                Showing lease offer for&nbsp;
                <button
                  className={cssJoin(
                    'link-underlined text-accent-blue',
                    styles['open-location-button'],
                  )}
                  data-color="accent"
                  onClick={openLocationModal}
                  type="button"
                >
                  {selectedLocation.formattedAddress}
                </button>
              </div>
            </CtaTrackingWrapper>
          </div>
        ))}
    </span>
  );
};

export const PriceFirstLine: React.FC<{
  price: string | undefined;
}> = ({ price }) => {
  return (
    <span key="purchase-price">
      <strong data-autoid="car-price">{price}</strong>
    </span>
  );
};
