import type { CarouselCarData } from '../types/carouselTypes';
import type { CarouselGraphCarData } from '../types/graphTypes';

const carMapper = (
  defaultCar: CarouselGraphCarData,
  colorMapping: Record<string, string>,
): CarouselCarData => {
  const carModelDictionaryKey = `CarColors.colorMapping.${defaultCar?.model?.key}`;
  const colorCode =
    carModelDictionaryKey && colorMapping[carModelDictionaryKey];

  const hexCar = defaultCar?.configuration?.colors?.find(
    (colorCar) => colorCar?.configurations?.[0]?.car?.color?.code === colorCode,
  )?.configurations?.[0]?.car;
  const car = hexCar ? { ...defaultCar, ...hexCar } : defaultCar;

  const image =
    car.visualizations?.[0]?.galleries?.custom?.images?.[0]?.small
      ?.transparentUrl;
  const hex = car.color?.content?.hex?.value;

  return {
    body:
      car?.carType?.content?.carTypeCategory?.formatted ||
      car?.carType?.content?.carTypeCategory?.value,
    bodyKey: car?.carType?.content?.carTypeCategory?.value,
    cashPrice: car.cashPrice?.price?.display,
    displayName: car?.model?.displayName?.value,
    engineType: car?.engine?.content.engineType?.formatted,
    engineTypeKey: car?.engine?.content.engineType?.value,
    image,
    key: car?.model?.key,
    hex,
  };
};

export default carMapper;
