'use client';

import { useIsMounted } from '@vcc-www/hooks/useIsMounted';
import { urlFromString, useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { cssJoin } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';
import NextLink from 'next/link';
import type React from 'react';
import { useEffect, useRef } from 'react';
import styles from '../SubNavigation.module.css';
import type { LinkProps, LinksProps } from './Links.view';

export const Link: React.FC<
  LinkProps & Pick<LinksProps, 'theme'> & { isInView: boolean }
> = ({ text, href, eventLabel, isInView, sources, theme }) => {
  const { contentPath: currentUrlPath } = useVolvoCarsUrl();

  const hasMounted = useIsMounted();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const hasScrolledIntoView = useRef(false);

  const { contentPath: linkPath } = urlFromString(href);
  const isCurrent = currentUrlPath === linkPath;

  useEffect(() => {
    if (
      isCurrent &&
      linkRef.current &&
      hasMounted &&
      isInView &&
      !hasScrolledIntoView.current
    ) {
      linkRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'end',
      });

      hasScrolledIntoView.current = true;
    }
  }, [isCurrent, hasMounted, isInView]);

  return (
    <Track eventLabel={eventLabel}>
      <NextLink
        href={href}
        className={cssJoin(
          'py-4 px-8 rounded whitespace-nowrap snap-center font-14 font-medium',
          styles.link,
          theme === 'light' && styles.lightLink,
        )}
        aria-current={isCurrent ? 'page' : undefined}
        ref={linkRef}
        data-sources={sources ? [sources?.text, sources?.href] : undefined}
      >
        {text}
      </NextLink>
    </Track>
  );
};
