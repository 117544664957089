'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { abTestingContextMapper } from '@vcc-package/leads-utils/abTesting';
import type {
  AnalyticsApiModel,
  FlexFormConfiguration,
  FullConfiguration,
  MarketConfiguration,
  MarketVehicleConfiguration,
  Package,
  PurposeType,
  RequestApiModel,
} from '@vcc-package/leads-utils/api';
import type {
  ApplicationInfo,
  LeadsApp,
} from '@vcc-package/leads-utils/constants';
import { LeadsApplications } from '@vcc-package/leads-utils/constants';
import {
  useAnalytics,
  useApi,
  useUrlData,
} from '@vcc-package/leads-utils/hooks';
import type {
  Navigation,
  NavigationFactory,
  Translations,
  UrlData,
} from '@vcc-package/leads-utils/types';
import type { API, PublicRuntimeConfig } from '@vcc-package/leads-utils/util';
import {
  StorageKey,
  getFeatureFlags,
  readFromSession,
  usePublicConfig,
} from '@vcc-package/leads-utils/util';
import type { MarketSite, SiteSlug } from '@volvo-cars/market-sites';

import type { FormValues } from '../../types/flexibleForm';
import { requestApiModelToFormValues } from '../../util/requestApiModelToFormValues';
import { ModelContextProvider } from './modelContext';
import { RetailerContextProvider } from './retailerContext';
import { TranslateContextProvider } from './translateContext';

export interface LeadsContextProperties
  extends Omit<ApplicationInfo, 'purpose'> {
  siteSlug: SiteSlug;
  API: API;
  formConfig: FlexFormConfiguration | null;
  formConfigs?: Partial<Record<PurposeType, FlexFormConfiguration | undefined>>;
  marketConfig: MarketConfiguration;
  purposeConfig: Package | undefined;
  vehicles?: MarketVehicleConfiguration[];
  vehiclesConfigs?: Partial<
    Record<PurposeType, MarketVehicleConfiguration[] | null>
  >;
  /** Selected model based on url query */
  clientId: string | undefined;
  /** Analytics data (GTM & UTM parameters) */
  analytics: AnalyticsApiModel;
  /** Url data (originalUrl & referralUrl) */
  urlData: UrlData;
  /** Data that has been sent with the form */
  submitData: RequestApiModel | null;
  /** Used for the cases when we deliberately need to change the app-purpose (ex: Quote + InsideSales) */
  setPurpose: (purpose: PurposeType | null) => void;
  purpose: PurposeType | null;
  /** Used to check if is EmbeddableForms */
  isEmbeddableForms: boolean;
  inOverlay: boolean;
  overlayOpen: boolean;
  consumerApp: string | null;
  publicRuntimeConfig: PublicRuntimeConfig;
  navigation: Navigation;
  features: Record<string, boolean | null>;
  prefilledData: FormValues | null;

  /** @private - only used internaly */
  fullConfig: FullConfiguration;
  isLoaded?: boolean;
}

export function createLeadsContext() {
  const ctx = React.createContext<LeadsContextProperties | undefined>(
    undefined,
  );
  function useCtx() {
    const context = React.useContext(ctx);
    if (!context) throw new Error('Missing LeadsContextProvider');
    return context;
  }
  return [useCtx, ctx] as const;
}

const [useCtx, ctx] = createLeadsContext();

export const LeadsContext = ctx;
export const useLeadsContext = useCtx;

export type LeadsContextProviderProps = React.PropsWithChildren<{
  appId: LeadsApp;
  queryObject: Record<string, string>;
  marketSite: Required<MarketSite>;
  fullConfig: FullConfiguration;
  clientId?: string | undefined;
  ssrUrlData: UrlData;
  ssrCookies?: string;
  consumerApp: string | null;
  navigationFactory: NavigationFactory;
  publicRuntimeConfig: PublicRuntimeConfig;
}>;

export type contextMapperType = (
  context: LeadsContextProperties,
) => Promise<LeadsContextProperties>;

export type LeadsExternalContextProps = {
  appId: LeadsApp;
  contextMapperFn?: contextMapperType;
  overridePurpose?: PurposeType | null;
  isEmbeddableForms?: boolean;
  inOverlay?: boolean;
  overlayOpen?: boolean;
  consumerApp: string | null;
  onExitIntentIntroductionComponent?: 'KeepMeUpdatedIntroductionPage';
};

export type LeadsExternalContextProviderProps = LeadsContextProviderProps &
  LeadsExternalContextProps;

export type ConfigStateObject = {
  marketConfig: MarketConfiguration;
  formConfig: FlexFormConfiguration;
  vechiclesConfig: MarketVehicleConfiguration[];
  translationConfig: Translations;
};

export const LeadsExternalContextProvider = ({
  children,
  marketSite,
  fullConfig,
  clientId,
  ssrUrlData,
  ssrCookies,
  appId,
  contextMapperFn,
  overridePurpose,
  isEmbeddableForms,
  inOverlay,
  consumerApp,
  navigationFactory,
  queryObject,
  publicRuntimeConfig: publicRuntimeConfigFromProp,
  overlayOpen,
}: LeadsExternalContextProviderProps): React.JSX.Element | null => {
  const publicRuntimeConfigLocal = usePublicConfig({
    isEmbeddableForms: isEmbeddableForms ?? false,
  });

  /* biome-ignore lint: space is required */
  const publicRuntimeConfig = useMemo(
    () => publicRuntimeConfigFromProp ?? publicRuntimeConfigLocal,
    [],
  );

  const applicationInfo = useMemo(() => {
    return LeadsApplications[appId];
  }, [appId]);

  const navigation = navigationFactory({
    query: queryObject,
    siteSlug: marketSite.siteSlug,
    appPath: applicationInfo.appPath,
    appId,
    publicRuntimeConfig,
  });
  const query = navigation.query;

  const analytics = useAnalytics({ appId: applicationInfo.appId, query });
  const urlData = useUrlData({ appId: applicationInfo.appId, ssrUrlData });

  const [purpose, setPurpose] = useState<PurposeType | null>(
    overridePurpose ?? (isEmbeddableForms ? null : applicationInfo.purpose),
  );

  const marketConfig = useMemo(
    () => (fullConfig.marketConfiguration ?? {}) as MarketConfiguration,
    [fullConfig.marketConfiguration],
  );

  const [dynamicFeatureFlags, setDynamicFeatureFlags] = useState<
    Record<string, boolean | null>
  >(marketConfig.dynamicFeatures ?? {});

  const isClientSide = typeof window !== 'undefined';

  const appEnv = publicRuntimeConfig.appEnv;
  const apiClient = useApi({
    appId: applicationInfo.appId,
    clientId,
    siteSlug: marketSite.siteSlug,
    isTesting: appEnv !== 'prod' && query?.testing === 'true',
    publicRuntimeConfig,
    ssrCookies: isClientSide ? document.cookie : ssrCookies,
  });

  const dynamicFeaturesRequest = useMemo(() => {
    if (isEmbeddableForms) {
      return Promise.resolve({ dynamicFeatures: dynamicFeatureFlags ?? {} });
    }

    return apiClient.getDynamicFeatureFlags();
  }, [apiClient, isEmbeddableForms, dynamicFeatureFlags]);

  const cdbId = query.cdb_id;
  const consumerDataRequest = cdbId
    ? apiClient.getConsumerData(cdbId)
    : Promise.resolve(null);

  const purposeConfig = useMemo(() => {
    const { purposeConfigs } = fullConfig.marketConfiguration ?? {};
    return purposeConfigs && purpose ? purposeConfigs[purpose] : undefined;
  }, [fullConfig, purpose]);

  const submitDataFetcher = useCallback(() => {
    return readFromSession<RequestApiModel>(
      applicationInfo.appId,
      StorageKey.submitData,
    );
  }, [applicationInfo.appId]);

  const formConfigs = useMemo(
    () =>
      (fullConfig.flexFormConfigurations ?? {}) as Record<
        PurposeType,
        FlexFormConfiguration | undefined
      >,
    [fullConfig.flexFormConfigurations],
  );
  const formConfig = useMemo(
    () => (purpose ? (formConfigs[purpose] ?? null) : null),
    [formConfigs, purpose],
  );

  const vehiclesConfigs = useMemo(
    () =>
      (fullConfig.vehicleConfigurations &&
        purpose &&
        fullConfig.vehicleConfigurations[purpose]) ??
      undefined,
    [fullConfig.vehicleConfigurations, purpose],
  );

  let features: LeadsContextProperties['features'] = useMemo(
    () => getFeatureFlags(marketConfig, dynamicFeatureFlags),
    [dynamicFeatureFlags, marketConfig],
  );

  const shouldShowSpinner =
    isEmbeddableForms || !!features?.showABTestingSpinner;

  /* biome-ignore lint: space is required */
  const [context, setContext] = useState<LeadsContextProperties>({
    ...applicationInfo,
    siteSlug: marketSite.siteSlug,
    API: apiClient,
    formConfig,
    marketConfig,
    purposeConfig,
    vehicles: vehiclesConfigs,
    clientId,
    analytics,
    urlData,
    get submitData() {
      return submitDataFetcher();
    },
    purpose,
    setPurpose,
    isEmbeddableForms: isEmbeddableForms ?? false,
    inOverlay: inOverlay ?? false,
    consumerApp,
    publicRuntimeConfig,
    navigation,
    features,
    prefilledData: null,
    fullConfig,
    isLoaded:
      !shouldShowSpinner || (features._not_set === undefined && isClientSide),
    overlayOpen: overlayOpen ?? false,
  });

  /* biome-ignore lint: Will get a re-render loop if we add all the dependencies */
  useEffect(() => {
    if (!isEmbeddableForms || !inOverlay) {
      return;
    }
    setContext((prev) => ({ ...prev, overlayOpen: overlayOpen ?? false }));
  }, [isEmbeddableForms, inOverlay, overlayOpen]);

  /* biome-ignore lint: Will get a re-render loop if we add all the dependencies */
  useEffect(() => {
    (async () => {
      let prefilledData = null;

      if (cdbId) {
        const consumerData = await consumerDataRequest;

        if (consumerData) {
          prefilledData = requestApiModelToFormValues(consumerData);
        }
      }

      if (features._not_set !== undefined) {
        const { dynamicFeatures } = await dynamicFeaturesRequest;
        const { _not_set, ...newFeatures } = features;
        marketConfig.dynamicFeatures = dynamicFeatures;
        setDynamicFeatureFlags(dynamicFeatures);
        features =
          query.disableexperiments === 'true'
            ? newFeatures
            : { ...newFeatures, ...dynamicFeatures };
      }

      if (!contextMapperFn) {
        setContext(
          await abTestingContextMapper({
            ...context,
            marketConfig,
            prefilledData,
            features,
            isLoaded:
              context.isLoaded ||
              (features._not_set === undefined && isClientSide),
          }),
        );
        return;
      }

      const newContext = await contextMapperFn(
        await abTestingContextMapper({
          ...context,
          ...applicationInfo,
          siteSlug: marketSite.siteSlug,
          API: apiClient,
          formConfig,
          formConfigs,
          marketConfig,
          purposeConfig,
          vehicles: vehiclesConfigs,
          clientId,
          analytics,
          urlData,
          get submitData() {
            return submitDataFetcher();
          },
          purpose,
          setPurpose,
          isEmbeddableForms: isEmbeddableForms ?? false,
          inOverlay: inOverlay ?? false,
          consumerApp,
          publicRuntimeConfig,
          navigation,
          features,
          prefilledData,
          fullConfig,
          isLoaded:
            context.isLoaded ||
            (features._not_set === undefined && isClientSide),
        }),
      );
      setContext({ ...newContext });
    })();
  }, [purpose]);

  return (
    <LeadsContext.Provider value={context}>
      <ModelContextProvider navigation={navigation}>
        <RetailerContextProvider>
          <TranslateContextProvider>{children}</TranslateContextProvider>
        </RetailerContextProvider>
      </ModelContextProvider>
    </LeadsContext.Provider>
  );
};

export const LeadsContextProvider = (props: LeadsContextProviderProps) => {
  return <LeadsExternalContextProvider {...props} />;
};
