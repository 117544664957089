'use client';
import type { LocationChangeEvent } from '@vcc-package/car-selector/types/location-change-event';
import { getUserLocationClient } from '@vcc-package/geolocation-utils';
import { LOG_SERVICES, log } from '@vcc-package/offers-utils';
import { getMarketSite } from '@volvo-cars/market-sites';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type {
  CarPriceHookReturnType,
  SelectedLocation,
} from '../../car-price.types';
import type { CarPriceData, CarPriceProps } from '../type/car-price.types';
import { getCarPriceElement } from '../utils/car-price.utils.car-price-string';
import { label } from '../utils/constants';

const US_PRICES_API_ENDPOINT = '/api/order/prices/us';

export const useCarPrice = ({
  clientName,
  deployEnv,
  model,
  qawwwHeader,
  siteSlug,
  testwwwHeader,
  enableSelectedLocation,
}: CarPriceProps): Pick<
  CarPriceHookReturnType,
  'carPrice' | 'supportedModels' | 'loading' | 'monthlyPrice'
> & { selectedLocation: SelectedLocation | null } => {
  const [selectedLocation, setSelectedLocation] =
    useState<SelectedLocation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [carPriceData, setCarPriceData] = useState<CarPriceData>({});

  const getUserLocationFromCookie = useCallback(async () => {
    const { ip, userInput } = await getUserLocationClient({
      getBrowserCoords: false,
    });
    let formattedAddress: string;
    if (userInput) {
      formattedAddress = `${userInput?.zipCode} ${userInput?.city}, ${userInput?.regionName}, ${userInput?.country}`;
      setSelectedLocation({
        location: {
          latitude: userInput?.latitude,
          longitude: userInput?.longitude,
        },
        zipCode: userInput?.zipCode ?? '',
        formattedAddress,
      });
    } else if (ip) {
      formattedAddress = `${ip?.zipCode} ${ip?.city}, ${ip?.region}, ${ip?.country}`;
      setSelectedLocation({
        location: {
          latitude: ip?.latitude,
          longitude: ip?.longitude,
        },
        zipCode: ip?.zipCode ?? '',
        formattedAddress,
      });
    }
  }, []);

  useEffect(() => {
    getUserLocationFromCookie();

    const handleLocationChange = (event: CustomEvent<LocationChangeEvent>) => {
      const { placeDetails } = event.detail;
      const location = placeDetails.location;
      if (!location) return;
      getUserLocationFromCookie();
    };

    document.addEventListener(
      'locationChangeEvent',
      handleLocationChange as EventListener,
    );
    return () => {
      document.removeEventListener(
        'locationChangeEvent',
        handleLocationChange as EventListener,
      );
    };
  }, [getUserLocationFromCookie]);

  //fetch data from offer-selector
  useEffect(() => {
    const fetchDataByZipcode = async () => {
      if (siteSlug && selectedLocation) {
        try {
          let baseUrl: string;
          switch (deployEnv) {
            case 'test':
              baseUrl = 'https://testwww.volvocars.com';
              break;
            case 'qa':
              baseUrl = 'https://qawww.volvocars.com';
              break;
            case 'dev':
              baseUrl = '';
              break;
            default:
              baseUrl = 'https://www.volvocars.com';
              break;
          }
          const res = await fetch(`${baseUrl}${US_PRICES_API_ENDPOINT}`, {
            method: 'POST',
            body: JSON.stringify({
              market: siteSlug,
              locale: getMarketSite(siteSlug).locale,
              zipCode: selectedLocation?.zipCode,
              modelKey: model,
              clientName,
            }),
            headers: {
              ...(deployEnv !== 'prod' && (testwwwHeader || qawwwHeader)
                ? {
                    testwww: testwwwHeader ?? '',
                    qawww: qawwwHeader ?? '',
                  }
                : {}),
              'x-client-name': clientName ?? '',
            },
          });
          if (res.ok) {
            const data = await res.json();
            setCarPriceData(data);
            setLoading(false);
          } else {
            const errorText = await res.text();
            throw new Error(
              `An error occurred while fetching US Car Price data. Response status: ${res.status}. Response error: ${errorText}`,
            );
          }
        } catch (e) {
          log.error({
            service: LOG_SERVICES.SHARED_US_CAR_PRICE_USE_CAR_PRICE,
            message: `Error in useCarPrice: ${(e as Error).message}`,
            clientName,
          });
        }
      }
    };

    fetchDataByZipcode();
  }, [
    model,
    clientName,
    selectedLocation,
    deployEnv,
    qawwwHeader,
    siteSlug,
    testwwwHeader,
  ]);

  const carPrice = useMemo(
    () =>
      getCarPriceElement({
        label,
        hasMonthly: !!carPriceData?.monthlyPrice,
        monthlyPrice: carPriceData?.monthlyPrice
          ? `${carPriceData?.monthlyPrice}/month`
          : undefined,
        hasPurchase: !!carPriceData?.purchasePrice,
        purchasePrice: `${carPriceData?.purchasePrice} MSRP`,
        monthlyPriceDisclaimer: carPriceData?.monthlyPriceDisclaimer,
        msrpDisclaimer: carPriceData?.msrpDisclaimer,
        enableSelectedLocation,
      }),
    [carPriceData, enableSelectedLocation],
  );

  return {
    carPrice,
    supportedModels: [],
    loading,
    monthlyPrice: carPriceData?.monthlyPrice,
    selectedLocation,
  };
};
